//权限设置
<template>
  <div class="enactTarget">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="568px"
    >
      <div class="examRule_head flex">
        <span>权限设置</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="enactTarget_main">
        <el-form ref="form" :rules="rules" :model="form" label-width="100px">
          <el-form-item label="经理:" prop="manager_id">
            <el-select v-model="form.manager_id" placeholder="请选择经理">
              <el-option
                v-for="item in managerList"
                :key="item.user_id"
                :label="item.name"
                :value="item.user_id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开始时间:" prop="start_time" label-width="100px">
            <el-date-picker
              v-model="form.start_time"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间:" prop="end_time" label-width="100px">
            <el-date-picker
              v-model="form.end_time"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <div class="txt">
            权限说明：设置某个经理看不到团队所有经纪人的业绩及佣金信息
          </div>
        </el-form>
        <div class="interview_main_footer center">
          <el-button @click="submit('form')">提交</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        manager_id: "",
        start_time: "",
        end_time: "",
      },
      rules: {
        manager_id: [
          { required: true, message: "请选择经理", trigger: "change" },
        ],
        end_time: [
          { required: true, message: "请选择结束时间", trigger: "change" },
        ],
        start_time: [
          { required: true, message: "请选择开始时间", trigger: "change" },
        ],
      },
      managerList: [], //经理列表
    };
  },
  methods: {
    //打开
    open() {
      this.$axios
        .userList({
          position:3
        })
        .then((res) => {
          this.managerList = res.data;
          this.dialogVisible = true;
        });
    },
    //关闭
    close() {
      this.dialogVisible = false;
    },
    //立即创建
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.setPower(this.form).then((res) => {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.form.manager_id = ''
            this.form.start_time = ''
            this.form.end_time = ''
            this.dialogVisible = false;
          });
        } else {
          return false;
        }
      });

      
      
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
  /deep/ .el-date-editor.el-input {
    width: 100%;
  }
}
/deep/ .el-input {
  width: 100%;
}
.enactTarget_main {
  padding: 30px 44px 40px 44px;
  /deep/ .el-select {
    width: 100%;
  }
  .txt {
    color: #ff9600;
    font-size: 14px;
  }
  /deep/ .el-button {
    width: 100%;
    margin-top: 90px;
  }
}
.interview_main_footer {
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
</style>
