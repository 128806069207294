// 借钥匙
<template>
  <div class="examRule p-t-30">
    <el-dialog title="" :show-close="false" :close-on-click-modal="false" :visible.sync="dialogVisible" width="568px">
      <div class="examRule_head flex">
        <span>制定目标</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <div class="examRule_main p-t-30 p-r-40 p-b-30 p-l-40">
        <div class="borrow-form">
          <el-form ref="form" :rules="rules" class="form" :model="form" label-width="100px">
            <el-form-item label="目标类型:" prop="type">
              <el-select v-model="form.type" placeholder="请选择">
                <el-option v-for="item in targetList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="新增客户:" prop="new_clients_num">
              <el-input v-model="form.new_clients_num" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="新增房源:" prop="new_house_num">
              <el-input v-model="form.new_house_num" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="新增带看:" prop="new_take_look_num">
              <el-input v-model="form.new_take_look_num" placeholder="请输入"></el-input>
            </el-form-item>

            <el-form-item label="新增跟进:" prop="new_follow_num">
              <el-input v-model="form.new_follow_num" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="新增钥匙方:" prop="new_key_num">
              <el-input v-model="form.new_key_num" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="新增信息方:" prop="new_msg_num">
              <el-input v-model="form.new_msg_num" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="新增委托方:" prop="new_wt_num">
              <el-input v-model="form.new_wt_num" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="新增图片方:" prop="new_img_num">
              <el-input v-model="form.new_img_num" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="新增维护方:" prop="new_wh_num">
              <el-input v-model="form.new_wh_num" placeholder="请输入"></el-input>
            </el-form-item>
          </el-form>
          <div class="interview_main_footer center">
            <el-button @click="submit('form')">提交</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      content: "", //规则
      form: {
        type: "",//类型：1 日目标；2 月目标
        new_clients_num: "",//新增客源
        new_house_num: "",//新增房源
        new_take_look_num: "",//新增带看
        new_about_look_num: "",//新增约看
        new_follow_num: "",//新增跟进
        new_yeji_num: "",//新增业绩
        new_key_num: "",// 新增钥匙方
        new_msg_num: "",// 新增信息方
        new_wt_num: "",// 新增委托方
        new_img_num: "",// 新增图片方
        new_wh_num: "",// 新增维护方
      },
      targetList: [
        {
          value: '1',
          label: '今日目标',
        },
        {
          value: '2',
          label: '本月目标',
        }
      ], //目标列表
      rules: {
        new_clients_num: [
          { required: true, message: '请输入新增客户', trigger: 'blur' }
        ],
        new_house_num: [
          { required: true, message: '请输入新增房源', trigger: 'blur' }
        ],
        new_take_look_num: [
          { required: true, message: '请输入新增带看', trigger: 'blur' }
        ],
        new_about_look_num: [
          { required: true, message: '请输入新增约看', trigger: 'blur' }
        ],
        new_follow_num: [
          { required: true, message: '请输入新增跟进', trigger: 'blur' }
        ],
        new_yeji_num: [
          { required: true, message: '请输入新增业绩', trigger: 'blur' }
        ],
        new_key_num: [
          { required: true, message: '请输入新增钥匙方', trigger: 'blur' }
        ],
        new_msg_num: [
          { required: true, message: '请输入新增信息方', trigger: 'blur' }
        ],
        new_wt_num: [
          { required: true, message: '请输入新增委托方', trigger: 'blur' }
        ],
        new_img_num: [
          { required: true, message: '请输入新增图片方', trigger: 'blur' }
        ],
        new_wh_num: [
          { required: true, message: '请输入新增维护方', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择目标类型', trigger: 'change' }
        ]
      }
    };
  },
  methods: {
    submit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.addAim(this.form).then(res => {
            this.dialogVisible = false
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.form.type = ''
            this.form.new_clients_num = ''
            this.form.new_house_num = ''
            this.form.new_take_look_num = ''
            this.form.new_about_look_num = ''
            this.form.new_follow_num = ''
            this.form.new_yeji_num = ''
            this.form.new_key_num = ''
            this.form.new_msg_num = ''
            this.form.new_wt_num = ''
            this.form.new_img_num = ''
            this.form.new_wh_num = ''
          })
        } else {
          return false;
        }
      });
    },
    //关闭
    close () {
      this.dialogVisible = false;
    },
    //打开
    open () {
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
  /deep/ .el-input__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
  .el-select {
    width: 100%;
  }
}
/deep/ .el-dialog {
  border-radius: 8px;
  overflow: hidden;
}
.interview_main_footer {
  padding: 30px 0 40px 0;
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
</style>
