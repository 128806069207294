<template>
  <div class="interview">
    <el-dialog
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="600px"
    >
      <!--achieve-pop start-->
      <div class="achieve-pop">
        <div class="pop-head">
          <i class="el-icon-circle-close" @click="close"></i>
        </div>
        <div class="pop-hd">
          <img class="img" :src="details.image" />
          <div class="two-txt">
            <div class="t">
              <span class="name m-r-20">{{ details.name }}</span>
              <span class="tel m-r-20">{{ details.mobile }}</span>
              <span class="address color_blue">#{{ details.city }}</span>
            </div>
            <div class="c">工号：{{ details.job_number }}</div>
          </div>
        </div>
        <!--pop-box start-->
        <div class="achieve-pop-con">
          <div class="pop-box">
            <div class="one-con">
              <div class="achieve-tit">今日目标</div>
              <ul class="flex-ul">
                <li>
                  <router-link :to="`/addTourist?type=1&id=${id}`">
                    <div class="t">
                    <span class="color_red">{{ details.day_clientCount }}</span>
                    /{{ details.day_new_clients_num }}
                  </div>
                  <div class="c">新增客户</div>
                  </router-link>
                  
                </li>
                <li>
                  <router-link :to="`/addHouse?type=1&id=${id}`">
                    <div class="t">
                    <span class="color_red">{{ details.day_houseCount }}</span>
                    /{{ details.day_new_house_num }}
                  </div>
                  <div class="c">新增房源</div>
                  </router-link>
                  
                </li>
                <li>
                  <router-link :to="`/bespectacled?type=1&id=${id}`">
                    <div class="t">
                    <span class="color_red">{{
                      details.day_daiLookCount
                    }}</span>
                    /{{ details.day_new_take_look_num }}
                  </div>
                  <div class="c">新增带看</div>
                  </router-link>
                  
                </li>

                <li>
                  <router-link :to="`/perIn?type=1&id=${id}`">
                    <div class="t">
                    <span class="color_red">{{
                      details.day_genLookCount
                    }}</span>
                    /{{ details.day_new_follow_num }}
                  </div>
                  <div class="c">新增跟进</div>
                  </router-link>
                  
                </li>
                <li>
                  <router-link :to="`/addSide?type=2&id=${id}&types=1`">
                    <div class="t">
                    <span class="color_red">{{ details.day_key_num }}</span> /{{
                      details.day_new_key_num
                    }}
                  </div>
                  <div class="c">新增钥匙方</div>
                  
                  </router-link>
                  
                </li>
                <li>
                  <router-link :to="`/addSide?type=1&id=${id}&types=1`">
                    <div class="t">
                    <span class="color_red">{{ details.day_msg_num }}</span> /{{
                      details.day_new_msg_num
                    }}
                  </div>
                  <div class="c">新增信息方</div>
                  </router-link>
                  
                </li>
                <li>
                  <router-link :to="`/addSide?type=3&id=${id}&types=1`">
                    <div class="t">
                    <span class="color_red">{{ details.day_wt_num }}</span> /{{
                      details.day_new_wt_num
                    }}
                  </div>
                  <div class="c">新增委托方</div>
                  </router-link>
                  
                </li>
                <li>
                  <router-link :to="`/addSide?type=4&id=${id}&types=1`">
                    <div class="t">
                    <span class="color_red">{{ details.day_img_num }}</span> /{{
                      details.day_new_img_num
                    }}
                  </div>
                  <div class="c">新增图片方</div>
                  </router-link>
                  
                </li>
                <li>
                  <router-link :to="`/addSide?type=3&id=${id}&types=1`">
                    <div class="t">
                    <span class="color_red">{{ details.day_wh_num }}</span> /{{
                      details.day_new_wh_num
                    }}
                  </div>
                  <div class="c">新增维护方</div>
                  </router-link>
                  
                </li>
              </ul>
            </div>
          </div>
          <!--pop-box end-->
          <!--pop-box start-->
          <div class="pop-box">
            <div class="one-con">
              <div class="achieve-tit">本月目标</div>
              <ul class="flex-ul">
                <li>
                  <router-link :to="`/addTourist?type=2&id=${id}`">
                    <div class="t">
                    <span class="color_red">{{
                      details.mouth_clientCount
                    }}</span>
                    /{{ details.mouth_new_clients_num }}
                  </div>
                  <div class="c">新增客户</div>
                  </router-link>
                  
                </li>
                <li>
                  <router-link :to="`/addHouse?type=2&id=${id}`">
                    <div class="t">
                    <span class="color_red">{{
                      details.mouth_houseCount
                    }}</span>
                    /{{ details.mouth_new_house_num }}
                  </div>
                  <div class="c">新增房源</div>
                  </router-link>
                  
                </li>

                <li>
                  <router-link :to="`/bespectacled?type=2&id=${id}`">
                    <div class="t">
                    <span class="color_red">{{
                      details.mouth_daiLookCount
                    }}</span>
                    /{{ details.mouth_new_take_look_num }}
                  </div>
                  <div class="c">新增带看</div>
                  </router-link>
                  
                </li>
                <li>
                  <router-link :to="`/perIn?type=2&id=${id}`">
                    <div class="t">
                    <span class="color_red">{{
                      details.mouth_genLookCount
                    }}</span>
                    /{{ details.mouth_new_follow_num }}
                  </div>
                  <div class="c">新增跟进</div>
                  </router-link>
                  
                </li>
                <li>
                  <router-link :to="`/addSide?type=2&id=${id}&types=2`">
                    <div class="t">
                    <span class="color_red">{{ details.mouth_key_num }}</span>
                    /{{ details.mouth_new_key_num }}
                  </div>
                  <div class="c">新增钥匙方</div>
                  </router-link>
                  
                </li>
                <li>
                  <router-link :to="`/addSide?type=1&id=${id}&types=2`">
                    <div class="t">
                    <span class="color_red">{{ details.mouth_msg_num }}</span>
                    /{{ details.mouth_new_msg_num }}
                  </div>
                  <div class="c">新增信息方</div>
                  </router-link>
                  
                </li>
                <li>
                  <router-link :to="`/addSide?type=3&id=${id}&types=2`">
                    <div class="t">
                    <span class="color_red">{{ details.mouth_wt_num }}</span>
                    /{{ details.mouth_new_wt_num }}
                  </div>
                  <div class="c">新增委托方</div>
                  </router-link>
                  
                </li>
                <li>
                  <router-link :to="`/addSide?type=4&id=${id}&types=2`">
                    <div class="t">
                    <span class="color_red">{{ details.mouthy_img_num }}</span>
                    /{{ details.mouth_new_img_num }}
                  </div>
                  <div class="c">新增图片方</div>
                  </router-link>
                  
                </li>
                <li>
                  <router-link :to="`/addSide?type=3&id=${id}&types=2`">
                    <div class="t">
                    <span class="color_red">{{ details.mouth_wh_num }}</span>
                    /{{ details.mouth_new_wh_num }}
                  </div>
                  <div class="c">新增维护方</div>
                  </router-link>
                  
                </li>
              </ul>
            </div>
          </div>
          <!--pop-box end-->
        </div>
      </div>
      <!--achieve-pop end-->
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      total: 0, //
      id: null, //
      details: {},
    };
  },
  methods: {
    //关闭
    close() {
      this.dialogVisible = false;
    },
    //获取列表
    getList() {
      this.$axios
        .checkYeJi({
          id: this.id,
        })
        .then((res) => {
          this.details = res.data;
        });
    },
    //打开
    open(id) {
      this.id = id;
      this.getList();
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.item-flex-img {
  display: flex;
  flex-wrap: wrap;
  .img {
    width: 120px;
    height: 120px;
    border-radius: 4px;
    margin: 0 5px 5px 5px;
  }
}
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
.form {
  display: flex;
  padding: 30px 40px;
  flex-wrap: wrap;
  justify-content: space-between;
  /deep/ .el-input {
    width: 320px;
  }
  /deep/ .el-input__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
}
.interview_main_footer {
  padding: 30px 0 40px 0;
  /deep/ .el-button {
    width: 480px;
    height: 56px;
    color: #fff;
    font-size: 18px;
    background: #3273f6;
    border-radius: 28px;
  }
}
.achieve-pop {
  padding: 20px 20px 30px 20px;
  .pop-head {
    display: flex;
    justify-content: flex-end;
    /deep/ .el-icon-circle-close {
      font-size: 24px;
      cursor: pointer;
    }
  }
  .pop-hd {
    display: flex;
    padding: 20px 40px;
    align-items: center;
    .img {
      width: 80px;
      height: 80px;
      border-radius: 100%;
    }
    .two-txt {
      margin-left: 15px;
      .t {
        font-size: 16px;
        display: flex;
      }
      .c {
        font-size: 14px;
        color: #666;
        margin: 10px 0px;
        line-height: 1.5;
      }
      .color_blue {
        color: #3273f6;
      }
    }
  }
  .achieve-pop-con {
    padding: 0 40px;
  }
  .pop-box {
    &:last-child {
      margin-top: 20px;
    }
    border: 1px solid #ebebeb;
    border-radius: 4px;
    .one-con {
      padding: 20px 30px;
      .flex-ul {
        display: flex;
        flex-wrap: wrap;
        margin: 20px 0px 10px 0px;
        li {
          width: 33.333%;
          text-align: center;
          margin: 15px 0px;
        }
        .t {
          font-size: 20px;
        }
        .color_red {
          color: #ff2121;
        }
        .c {
          font-size: 16px;
          color: #666;
          margin-top: 5px;
        }
      }
    }
  }
}
.achieve-tit {
  font-size: 18px;
  position: relative;
  padding-left: 15px;
}
.achieve-tit:before {
  display: block;
  width: 4px;
  height: 20px;
  background: #3273f6;
  border-radius: 2px;
  position: absolute;
  left: 0px;
  top: 50%;
  margin-top: -10px;
  content: "";
}
</style>
